import React from "react"

import Title from 'components/Title'
import Constrain from 'components/Constrain'
import { DualVideoContainer } from 'components/Video'

import Container from 'components/Container'

import image from 'images/shop-management-video.png'
import mobileImage from 'images/customer-notifications-mobile.jpg'
// import { useSiteMetadata } from "../../hooks/useSiteMetadata"

import { useTranslation } from 'gatsby-plugin-react-i18next'

import Footer from "components/Footer"
import Header from "components/Header"

export default function Home() {

  const { t } = useTranslation()

//   const { title } = useSiteMetadata()

  return (
    <>
    <Header />
    <main>
      <Title 
        title={ t('Customer notifications') } 
        subtitle={ t('Your customers are automatically notified as their vehicle progresses') }
        dark 
      />
      <Constrain>
        {/* <Video poster={ image } src='https://autoservice.nyc3.cdn.digitaloceanspaces.com/media/customer-notifications.mp4?v=2' /> */}
        <DualVideoContainer
            desktop={{
                poster: image,
                src: `https://autoservice.nyc3.cdn.digitaloceanspaces.com/media/customer-notifications.mp4`
            }}
            mobile={{
                poster: mobileImage,
                src: `https://autoservice.nyc3.cdn.digitaloceanspaces.com/media/customer-notifications-mobile.mp4?v=2`
            }}
        />
      </Constrain>

      <Container>

        <h2>{ t(`Appointment reminders`) }</h2>
        <p>
          { t(`Reduce missed appointments with automatic reminders and immediately know if a customer is unable to make it.`) }
        </p>

        <h2>{ t(`Vehicle pickup`) }</h2>
        <p>
          { t(`Optionally, offer vehicle pickup service and we will let your customer know as soon as you are on the way.`) }
        </p>

        <h2>{ t(`Online payments`) }</h2>
        <p>
          { t(`Just finished that oil change? Attach an invoice and get paid online right away.`) }
        </p>

      </Container>
  </main>
  <Footer />
  </>
  )
}
